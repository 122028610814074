import { cn } from "@wind/ui";

interface CloudProps {
  className?: string;
  flip?: boolean;
}

export const Cloud = ({ className, flip = false }: CloudProps) => {
  return (
    <img
      src="https://windmill-storage-prod.s3.us-east-1.amazonaws.com/marketing/animation-cloud.png"
      className={cn(
        "absolute object-cover opacity-80",
        {
          "scale-x-[-1] animate-float-left-right": flip,
          "animate-float-right-left": !flip,
        },
        className
      )}
      alt=""
    />
  );
};

const CloudyBackground = () => {
  return (
    <div className="fixed inset-0 -z-50 from-blue-100 to-blue-200 bg-gradient-to-b">
      <div className="absolute bottom-0 left-0 right-0 translate-y-[150px]">
        <Cloud className="bottom-0 translate-y-1/2 left-0 w-64" flip />
        <Cloud className="bottom-0 translate-y-1/2 left-[10%] w-[500px] opacity-90" />
        <Cloud className="bottom-0 translate-y-1/2 left-[20%] w-[800px]" flip />
        <Cloud className="bottom-0 translate-y-1/2 left-[30%] w-[600px] opacity-75" />
        <Cloud className="bottom-0 translate-y-1/2 left-[45%] w-[450px] opacity-85" flip />
        <Cloud className="bottom-0 translate-y-1/2 left-[55%] w-[550px] opacity-70" />
        <Cloud className="bottom-0 translate-y-1/2 left-[65%] w-[350px] opacity-95" flip />
        <Cloud className="bottom-0 left-[75%] w-[420px] opacity-80" />
        <Cloud className="bottom-0 left-[80%] w-[400px]" flip />
        <Cloud className="bottom-0 translate-y-1/2 left-[90%] w-[300px] opacity-90" />
      </div>
    </div>
  );
};

export default CloudyBackground;
